// app
export const SET_ERROR = 'SET_ERROR'
export const SET_MESSAGE = 'SET_MESSAGE'
export const APP_RESET = 'APP_RESET'
export const SET_PROCESSING = 'SET_PROCESSING'
export const SET_DRAWER = 'SET_DRAWER'
export const SET_SELECT_TYPE_DIALOG = 'SET_SELECT_TYPE_DIALOG'
// event
export const SET_EVENT = 'SET_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const SET_MEETING_TIME = 'SET_MEETING_TIME'
export const SET_RANGE = 'SET_RANGE'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_OLD_EVENTS = 'SET_OLD_EVENTS'
export const SET_DETAILS = 'SET_DETAILS'
export const CLEAR_DETAILS = 'CLEAR_DETAILS'
export const RESET = 'RESET'
// user
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const TEMPORARY_LOGIN = 'TEMPORARY_LOGIN'
export const SET_TERMINAL_LISTS = 'SET_TERMINAL_LISTS'
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const SET_MAX_INVITE = 'SET_MAX_INVITE'

//
