export const colors = Object.freeze({
  list: ['#2980B9', 'red', '#A463BF', '#558B2F', '#FB8C00'],
  default: '#2980B9',
})

/* IE判定関数を定義しておく */
export const isIe = () => {
  var userAgent = window.navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
    return true
  }
  return false
}
