const memberFactory = (form) => {
  return form.members.map((member, i) => {
    return {
      id: member.id,
      hostFlag: form.connectType === 'D' ? true : false,
      start: null,
      occupyFlag: false,
      chairmanFlag: i === form.distributorID && form.screenMode >= 10 ? true : false,
      vaCtrlFlag: true,
      vaNineOrderFlag: false,
    }
  })
}

export default memberFactory
