const makeFormDirect = (start, end) => {
  return {
    summary: '',
    color: 0,
    connectType: 'D',
    inviteNum: 1,
    start: start,
    end: end,
    repeatSettings: null,
    endOfRepeat: null,
    screenMode: 0,
    noChargeFlag: false,
    mcu: 0,
    members: [],
    distributorID: 0,
  }
}

const makeFormCloud = (start, end) => {
  return {
    summary: '',
    color: 0,
    connectType: 'M',
    inviteNum: 0,
    start: start,
    end: end,
    repeatSettings: null,
    endOfRepeat: null,
    screenMode: 0,
    noChargeFlag: false,
    mcu: Number(process.env.VUE_APP_DEFAULT_MCU),
    members: [],
    distributorID: 0,
  }
}

const FormNew = function (type, start, end) {
  this.summary = ''
  this.color = 0
  this.connectType = type
  this.inviteNum = type === 'D' ? 1 : 0
  this.start = start
  this.end = end
  this.repeatSettings = null
  this.endOfRepeat = null
  this.screenMode = 0
  this.noChargeFlag = false
  this.mcu = Number(process.env.VUE_APP_DEFAULT_MCU)
  this.members = []
  this.distributorID = 0
}

const FormEdit = function (value) {
  this.summary = value.name === 'タイトルなし' ? null : value.name
  this.connectType = value.connectType || 'M'
  this.color = value.colorCode || 0
  this.inviteNum = value.inviteNum || 0
  this.start = value.start
  this.end = value.end
  this.repeatSettings = null
  this.endOfRepeat = null
  this.screenMode = value.screenMode || 0
  this.noChargeFlag = value.noChargeFlag || false
  this.mcu = value.mcu || Number(process.env.VUE_APP_DEFAULT_MCU)
  this.members = value.members.map((member) => {
    return {
      id: member.id,
      name: member.name || member.id,
    }
  })
  this.distributorID =
    value.screenMode >= 10 ? value.members.findIndex((member) => member.chairmanFlag) : null
}

const formFactory = {
  // コンストラクタパターン
  create(type, start, end) {
    return new FormNew(type, start, end)
  },
  edit(details) {
    return new FormEdit(details)
  },
  // ファクトリオブジェクトパターン
  createDirect: (start, end) => makeFormDirect(start, end),
  createCloud: (start, end) => makeFormCloud(start, end),
}

export default formFactory
