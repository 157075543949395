import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/ja'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.locale('ja')

const Date = {
  // 今回は特にオプションは受け取らないのでVueのみの受け取り
  install(Vue) {
    // Vue.$dateで使えるようにプロトタイプに追加
    Object.defineProperty(Vue.prototype, '$date', {
      get() {
        return dayjs
      },
    })
    Vue.prototype.$format = (parse, format) => {
      const date = parse ? dayjs(parse) : dayjs()
      return date.format(format)
    }
  },
}

export default Date
