import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { app, user, event } from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    event,
  },
  plugins: [createPersistedState({ key: 'mypage', storage: window.sessionStorage })], // この行でvuexに「vuex-persistedstate」を追加
})
