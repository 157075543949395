import Vue from 'vue'
import formFactory from '../modules/formFactory'
import memberFactory from '../modules/memberFactory'

const plugin = {
  install() {
    Vue.prototype.$formFactory = formFactory
    Vue.prototype.$memberFactory = memberFactory
  },
}

Vue.use(plugin)
