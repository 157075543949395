import { colors } from '@/modules/common'
import * as types from '../mutation-types'
//import dayjs from 'dayjs';

const getDefaultState = () => ({
  // カレンダー表示の開始日と終了日
  range: {
    startOf: null,
    endOf: null,
    month: null,
  },
  // 予約開始時刻と、終了時刻
  meetingTime: {
    start: null,
    end: null,
  },
  // 開始日から終了日までのイベント一覧
  events: [],
  // イベントの詳細
  details: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    [types.SET_EVENTS](state, payload) {
      state.events.length = 0
      state.events = [...payload]
    },
    [types.SET_OLD_EVENTS](state, payload) {
      Array.prototype.push.apply(state.events, payload)
    },
    [types.SET_EVENT](state, payload) {
      state.events.push(payload)
    },
    [types.UPDATE_EVENT](state, { index: index, data: data }) {
      state.events.splice(index, 1, data)
    },
    [types.REMOVE_EVENT](state, payload) {
      state.events.length = 0
      state.events = [...payload]
    },
    [types.SET_RANGE](state, payload) {
      state.range.startOf = payload.startOf
      state.range.endOf = payload.endOf
      state.range.month = payload.month
    },
    [types.SET_DETAILS](state, payload) {
      state.details = { ...payload }
      //state.details.colorCode = colors.list.findIndex(payload.color);
      //state.details.start = state.details.start.slice(0, 16);
      //state.details.end = state.details.end.slice(0, 16);
    },
    [types.CLEAR_DETAILS](state) {
      state.details = null
    },
    [types.SET_MEETING_TIME](state, payload) {
      state.meetingTime.start = payload.format('YYYY-MM-DD H:mm')
      state.meetingTime.end = payload.add(1, 'hour').format('YYYY-MM-DD H:mm')
    },
    [types.RESET](state) {
      Object.assign(state, getDefaultState())
    },
  },
  getters: {
    range: (state) => state.range,
    events: (state) => state.events,
    getDetailsById: (state) => (id) => state.events.find((e) => e.id === id),
    details: (state) => state.details,
    isDetails: (state) => state.isDetails,
    meetingTime: (state) => state.meetingTime,
  },
  actions: {
    // 期間の更新
    [types.SET_RANGE]({ commit }, payload) {
      commit(types.SET_RANGE, payload)
    },
    // イベント一覧の更新
    [types.SET_EVENTS]({ commit }, payload) {
      const result = payload.reduce((prev, event) => {
        prev.push({
          name: event.summary || 'タイトルなし',
          start: event.start,
          end: event.end,
          members: event.members,
          color: colors.list[event.color],
          colorCode: event.color,
          timed: true,
          id: event.id,
          confCd: event.confCd || null,
          connectType: event.connectType,
          inviteNum: event.inviteNum,
          repeatSettings: event.repeatSettings,
          screenMode: event.screenMode,
          noChargeFlag: event.noChargeFlag,
          mcu: event.mcu,
          urls: event.urls,
          supportId: event.supportId,
        })
        return prev
      }, [])
      commit(types.SET_EVENTS, result)
    },
    // 旧予約の追加
    [types.SET_OLD_EVENTS]({ commit }, payload) {
      const result = payload.map((event) => {
        return {
          name: event.summary || 'タイトルなし',
          start: event.start,
          end: event.end,
          members: event.members,
          color: '#2980B9',
          colorCode: 0,
          timed: true,
          id: event.id,
          connectType: 'O',
          inviteNum: 0,
          repeatSettings: '繰り返しなし',
          screenMode: event.screenMode,
          noChargeFlag: event.noChargeFlag,
          mcu: event.mcu,
          urls: [],
          supportId: '',
        }
      })
      commit(types.SET_OLD_EVENTS, result)
    },
    // イベントの追加
    [types.SET_EVENT]({ commit }, payload) {
      const data = {
        name: payload.summary || 'タイトルなし',
        start: payload.start,
        end: payload.end,
        members: payload.members,
        color: colors.list[payload.color],
        colorCode: payload.color,
        timed: true,
        id: payload.id,
        connectType: payload.connectType,
        inviteNum: payload.inviteNum,
        repeatSettings: payload.repeatSettings,
        screenMode: payload.screenMode,
        noChargeFlag: payload.noChargeFlag,
        mcu: payload.mcu,
        urls: payload.urls,
        supportId: payload.supportId,
      }
      // payload.start = payload.start.slice(0, 16);
      //payload.end = payload.end.slice(0, 16);
      commit(types.SET_EVENT, data)
    },
    //イベントの変更
    [types.UPDATE_EVENT]({ state, commit }, payload) {
      const index = state.events.findIndex((d) => d.id === payload.id)

      const data = {
        name: payload.summary || 'タイトルなし',
        start: payload.start,
        end: payload.end,
        members: payload.members,
        color: colors.list[payload.color],
        colorCode: payload.color,
        timed: true,
        id: payload.id,
        connectType: payload.connectType,
        inviteNum: payload.inviteNum,
        repeatSettings: payload.repeatSettings,
        screenMode: payload.screenMode,
        noChargeFlag: payload.noChargeFlag,
        mcu: payload.mcu,
        urls: payload.urls,
        supportId: payload.supportId,
      }

      commit(types.UPDATE_EVENT, { index: index, data: data })
    },
    // イベントの削除
    [types.REMOVE_EVENT]({ commit, state }, id) {
      const result = state.events.filter((d) => d.id !== id)
      commit(types.REMOVE_EVENT, result)
    },
    // イベント詳細のセット
    [types.SET_DETAILS]({ commit }, payload) {
      payload.start = payload.start.slice(0, 16)
      payload.end = payload.end.slice(0, 16)
      commit(types.SET_DETAILS, payload)
    },
    [types.RESET]({ commit }) {
      commit(types.RESET)
    },
    [types.CLEAR_DETAILS]({ commit }) {
      commit(types.CLEAR_DETAILS)
    },
    [types.SET_MEETING_TIME]({ commit }, payload) {
      commit(types.SET_MEETING_TIME, payload)
    },
  },
}
