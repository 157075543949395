import * as types from '../mutation-types'

// 初期値
const getDefaultState = () => ({
  loggedIn: false,
  provisionalAuth: false,
  id: null,
  name: null,
  type: null,
  maxInvite: 0,
  detailPermission: false,
  transferPermission: false,
  issueMonths: [],
  frequentUsers: [],
  relatedUsers: [],
  adminAuthority: false,
  staffId: null,
  staffName: null,
  templates: [],
  multiRooms: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    // ユーザー情報の登録
    [types.LOGIN](state, payload) {
      state.loggedIn = true
      state.id = payload.id
      state.name = payload.name || ''
      state.type = payload.type || ''
      state.frequentUsers = [...payload.list] || []
      state.relatedUsers = [...payload.relatedCustomers] || []
      state.adminAuthority = payload.staffId ? true : false
      state.staffId = payload.staffId || null
      state.staffName = payload.staffName || null
      state.maxInvite = payload.maxInvite || 0
      state.detailPermission = payload.detailPermission
      state.transferPermission = payload.transferPermission
      state.issueMonths = [...payload.issueMonths] || []
      state.templates = [...payload.templates] || []
      state.multiRooms = [...payload.multiRooms] || []
    },
    // ユーザー情報の解除
    [types.LOGOUT](state) {
      Object.assign(state, getDefaultState())
    },
    // 仮認証処理
    [types.TEMPORARY_LOGIN](state, payload) {
      state.provisionalAuth = payload
    },
    // 関連ユーザー情報のセット
    [types.SET_TERMINAL_LISTS](state, payload) {
      state.frequentUsers.length = 0
      state.frequentUsers = [...payload]
    },
    // テンプレートのセット
    [types.SET_TEMPLATES](state, payload) {
      state.templates.length = 0
      state.templates = [...payload]
    },
    // 最大招待数のセット
    [types.SET_MAX_INVITE](state, payload) {
      state.maxInvite = payload
    },
  },
  getters: {
    user: (state) => state,
    id: (state) => state.id,
    name: (state) => state.name,
    type: (state) => state.type,
    maxInvite: (state) => state.maxInvite,
    detailPermission: (state) => state.detailPermission,
    transferPermission: (state) => state.transferPermission,
    issueMonths: (state) => state.issueMonths,
    frequentUsers: (state) => state.frequentUsers,
    relatedUsers: (state) => state.relatedUsers,
    terminal: (state, id) => state.frequentUsers.find((value) => value.id === id),
    loggedIn: (state) => state.loggedIn,
    provisionalAuth: (state) => state.provisionalAuth,
    adminAuthority: (state) => state.adminAuthority,
    staffId: (state) => state.staffId,
    staffName: (state) => state.staffName,
    templates: (state) => state.templates,
    multiRooms: (state) => state.multiRooms,
  },
  actions: {
    [types.LOGIN]({ commit }, payload) {
      // 会議テンプレートのソート
      payload.templates.sort((a, b) => a.number - b.number)
      commit(types.LOGIN, payload)
    },
    [types.LOGOUT]({ commit, dispatch }) {
      commit(types.LOGOUT)
      dispatch('event/RESET', null, { root: true })
    },
    [types.SET_TERMINAL_LISTS]({ commit }, payload) {
      commit(types.SET_TERMINAL_LISTS, payload)
    },
    [types.SET_TEMPLATES]({ commit }, payload) {
      // 会議テンプレートのソート
      payload.sort((a, b) => a.number - b.number)
      commit(types.SET_TEMPLATES, payload)
    },
    [types.TEMPORARY_LOGIN]({ commit }, isAuth) {
      commit(types.TEMPORARY_LOGIN, isAuth)
    },
    [types.SET_MAX_INVITE]({ commit }, maxInvite) {
      commit(types.SET_MAX_INVITE, maxInvite)
    },
  },
}
