<template>
  <v-app>
    <BaseLoading />
    <BaseError />
    <BaseSnackBar />
    <BaseDrawer v-if="loggedIn" />
    <BaseAppBar v-if="loggedIn" />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <BaseFooter />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    BaseFooter: () => import('@/components/bases/BaseFooter'),
    BaseSnackBar: () => import('@/components/bases/BaseSnackBar'),
    BaseLoading: () => import('@/components/bases/BaseLoading'),
    BaseError: () => import('@/components/bases/BaseError'),
    BaseAppBar: () => import('@/components/bases/BaseAppBar'),
    BaseDrawer: () => import('@/components/bases/BaseDrawer'),
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
}
</script>
