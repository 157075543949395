import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import {
  required,
  max,
  numeric,
  digits,
  alpha_num,
  length,
  max_value,
  between,
} from 'vee-validate/dist/rules'
import dayjs from 'dayjs'

localize('ja', ja)

extend('required', {
  ...required,
  message: '{_field_}は必須項目です',
})
extend('between', {
  ...between,
})

extend('digits', {
  ...digits,
})

extend('max', {
  ...max,
  message: '{_field_}は{length}文字以内で入力してください',
  params: ['length'],
})
extend('max_value', {
  ...max_value,
  message: '{_field_}は{length}までで入力してください',
  params: ['length'],
})
extend('numeric', {
  ...numeric,
  message: '{_field_}は数字のみです',
}) // アドレス用
extend('length', {
  ...length,
  message: '{_field_}は10桁の数字で入力してください',
})

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_}は半角英数字で入力してください',
})

extend('before', {
  params: [{ name: 'date' }],
  validate(value, { date }) {
    const now = dayjs()
    return dayjs(`${date} ${value}`).diff(now, 'minutes') > -10
  },
  message: '開始日時が過去に設定されています',
})

extend('beforeNew', {
  validate(value) {
    const now = dayjs()
    return dayjs(value).diff(now, 'minutes') > -10
  },
  message: '開始日時が過去に設定されています',
})

extend('min_location', {
  params: [{ name: 'point' }],
  validate(value, { point }) {
    return value.length + point >= 2
  },
  message: '{_field_}を2地点以上選んでください',
})

// 終了時刻のバリデーション（終了時刻が現在時刻より先に設定されているかどうか）
extend('past', {
  params: [{ name: 'date' }],
  validate(value, { date }) {
    const now = dayjs()
    return now.isBefore(dayjs(`${date} ${value}`))
  },
  message: '終了日時が過去の予約は作成できません',
})

// 終了日時が１か月以上先の予約は不可
extend('over1month', {
  params: [{ name: 'date' }],
  validate(value, { date }) {
    const now = dayjs()
    return dayjs(`${date} ${value}`).diff(now, 'day') < 31
  },
  message: '１か月以上先の予約は作成できません',
})

// 終了日時が１か月以上先の予約は不可
extend('over1monthNew', {
  validate(value) {
    const now = dayjs()
    return dayjs(value).diff(now, 'day') < 31
  },
  message: '１か月以上先の予約は作成できません',
})
// 合計地点数をバリデート（４地点以上はエラー）
extend('totalCount', {
  params: [
    {
      name: 'point',
      isTarget: true,
    },
  ],
  validate(field, { point }) {
    return field + point.length <= 4
  },
  message: '最大地点数を超えています',
})

extend('minCount', {
  params: [
    {
      name: 'point',
      isTarget: true,
    },
  ],
  validate(field, { point }) {
    return field + point.length >= 2
  },
  message: '１地点での予約は出来ません',
})

extend('overCount', {
  validate(field) {
    return field.length <= 4
  },
  message: '最大地点数を超えています',
})

extend('passwordConfirm', {
  params: ['target'],
  validate(field, { target }) {
    return field === target
  },
  message: 'パスワードが一致しません',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
