import * as types from '../mutation-types'

const getDefaultState = () => ({
  error: {
    message: null,
  },
  notice: {
    message: null,
  },
  processing: false,
  drawer: null,
  selectTypeDialog: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    [types.SET_ERROR](state, payload) {
      state.error = { ...payload }
    },
    [types.SET_MESSAGE](state, payload) {
      state.notice.message = payload
    },
    [types.SET_PROCESSING](state, payload) {
      state.processing = payload
    },
    [types.SET_DRAWER](state, payload) {
      state.drawer = payload
    },
    [types.SET_SELECT_TYPE_DIALOG](state, payload) {
      state.selectTypeDialog = payload
    },
    [types.APP_RESET](state) {
      Object.assign(state, getDefaultState())
    },
  },
  getters: {
    error: (state) => state.error,
    notice: (state) => state.notice,
    isProcessing: (state) => state.processing,
    drawer: (state) => state.drawer,
    selectTypeDialog: (state) => state.creationNewDialog,
  },
  actions: {
    // エラーデータをセットする
    [types.SET_ERROR]({ commit }, err) {
      commit(types.SET_ERROR, err)
    },
    [types.SET_MESSAGE]({ commit }, message) {
      commit(types.SET_MESSAGE, message)
    },
    [types.APP_RESET]({ commit }) {
      commit(types.APP_RESET)
    },
    [types.SET_PROCESSING]({ commit }, active) {
      commit(types.SET_PROCESSING, active)
    },
    [types.SET_DRAWER]({ commit }, active) {
      commit(types.SET_DRAWER, active)
    },
    [types.SET_SELECT_TYPE_DIALOG]({ commit }, active) {
      commit(types.SET_SELECT_TYPE_DIALOG, active)
    },
  },
}
