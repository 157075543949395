import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { isIe } from '@/modules/common.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: () => import('@/views/UserLogin.vue'),
    beforeEnter: (to, from, next) => {
      if (isIe()) {
        // InternetExplorer判定
        next({ path: '/not_support', query: { redirect: to.fullPath } })
      } else if (store.getters['user/loggedIn']) {
        next({ path: '/schedule', query: { redirect: to.fullPath } })
      } else {
        next()
      }
    },
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import('@/views/EventSchedule.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/lists',
    name: 'Lists',
    component: () => import('@/views/TerminalList.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next({ path: '/schedule', query: { redirect: to.fullPath } })
      } else {
        next()
      }
    },
  },
  {
    path: '/statement-of-account',
    name: 'StatementOfAccount',
    component: () => import('@/views/StatementOfAccount.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/usageTime',
    name: 'UsageTime',
    component: () => import('@/views/UsageTime.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/change_password',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn'] || store.getters['user/provisionalAuth']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/staffLogin',
    name: 'StaffLogin',
    component: () => import('@/views/StaffLogin.vue'),
  },
  {
    path: '/url',
    name: 'ManageUrl',
    component: () => import('@/views/ManageUrl.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn'] || store.getters['user/provisionalAuth']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/UserGuide.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn'] || store.getters['user/provisionalAuth']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/manual',
    name: 'Manual',
    component: () => import('@/views/UserManual.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn'] || store.getters['user/provisionalAuth']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/userfees',
    name: 'UserFees',
    component: () => import('@/views/UserFees.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn'] || store.getters['user/provisionalAuth']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/inSession',
    name: 'InSession',
    component: () => import('@/views/InSessionEvents.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/faq',
    name: 'FaqPage',
    component: () => import('@/views/FaqPage.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/usual_conference',
    name: 'UsualConference',
    component: () => import('@/views/UsualConference.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        next()
      } else {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
    },
  },
  {
    path: '/not_support',
    name: 'NotSupport',
    component: () => import('@/views/NotSupport.vue'),
  },
  {
    path: '/error',
    name: '500',
    component: () => import('@/views/error_500.vue'),
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: 'お探しのページは見つかりませんでした' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
